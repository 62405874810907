import React from "react";
import spiner from "../../assets/loader.gif";
import bg from "../../assets/background4.png";
import "./style.scss";

const Loader = () => {
  return (
    <div className="loader" style={{ backgroundImage: `url(${bg})` }}>
      <div
        className="spiner"
        style={{ backgroundImage: `url(${spiner})` }}
      ></div>
    </div>
  );
};

export default Loader;
